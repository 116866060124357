import { to_currency } from "helpers/number"

// String helper functions
String.prototype.trim = function() {
  return this.replace(/^\s+|\s+$/g, "")
}
String.prototype.ltrim = function() {
  return this.replace(/^\s+/, "")
}
String.prototype.rtrim = function() {
  return this.replace(/\s+$/, "")
}
String.prototype.titleCase = function() {
  var str = ""
  var wrds = this.replace("_", " ").split(" ")
  for (let keyvar in wrds) {
    str +=
      " " +
      wrds[keyvar].substr(0, 1).toUpperCase() +
      wrds[keyvar].substr(1, wrds[keyvar].length).toLowerCase()
  }
  return str.ltrim()
}
String.prototype.pluralize = function(num) {
  if (num == 1) return this
  else return this + "s"
}
String.prototype.startsWith = function(str) {
  // We use `lastIndexOf` instead of `indexOf` to avoid tying execution
  // time to string length when string doesn't start with pattern.
  return this.lastIndexOf(str, 0) === 0
}

if (!Array.from) {
  Array.from = (function() {
    var toStr = Object.prototype.toString
    var isCallable = function(fn) {
      return typeof fn === "function" || toStr.call(fn) === "[object Function]"
    }
    var toInteger = function(value) {
      var number = Number(value)
      if (isNaN(number)) {
        return 0
      }
      if (number === 0 || !isFinite(number)) {
        return number
      }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number))
    }
    var maxSafeInteger = Math.pow(2, 53) - 1
    var toLength = function(value) {
      var len = toInteger(value)
      return Math.min(Math.max(len, 0), maxSafeInteger)
    }

    // The length property of the from method is 1.
    return function from(arrayLike /*, mapFn, thisArg */) {
      // 1. Let C be the this value.
      var C = this

      // 2. Let items be ToObject(arrayLike).
      var items = Object(arrayLike)

      // 3. ReturnIfAbrupt(items).
      if (arrayLike == null) {
        throw new TypeError(
          "Array.from requires an array-like object - not null or undefined"
        )
      }

      // 4. If mapfn is undefined, then let mapping be false.
      var mapFn = arguments.length > 1 ? arguments[1] : void undefined
      var T
      if (typeof mapFn !== "undefined") {
        // 5. else
        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
        if (!isCallable(mapFn)) {
          throw new TypeError(
            "Array.from: when provided, the second argument must be a function"
          )
        }

        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 2) {
          T = arguments[2]
        }
      }

      // 10. Let lenValue be Get(items, "length").
      // 11. Let len be ToLength(lenValue).
      var len = toLength(items.length)

      // 13. If IsConstructor(C) is true, then
      // 13. a. Let A be the result of calling the [[Construct]] internal method
      // of C with an argument list containing the single item len.
      // 14. a. Else, Let A be ArrayCreate(len).
      var A = isCallable(C) ? Object(new C(len)) : new Array(len)

      // 16. Let k be 0.
      var k = 0
      // 17. Repeat, while k < len… (also steps a - h)
      var kValue
      while (k < len) {
        kValue = items[k]
        if (mapFn) {
          A[k] =
            typeof T === "undefined"
              ? mapFn(kValue, k)
              : mapFn.call(T, kValue, k)
        } else {
          A[k] = kValue
        }
        k += 1
      }
      // 18. Let putStatus be Put(A, "length", len, true).
      A.length = len
      // 20. Return A.
      return A
    }
  })()
}

// Global Helper Functions
window.number_to_currency = to_currency

window.number_with_delimiter = function(number, delimiter, separator) {
  try {
    var delimiter = delimiter || ","
    var separator = separator || "."

    var parts = number.toString().split(".")
    parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + delimiter)
    return parts.join(separator)
  } catch (e) {
    return number
  }
}

window.isMobileDevice = function() {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  )
}
