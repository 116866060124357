// alert_type: e.g. error
// msg: error message to be shown
// target_container: the container id which the error message going to be inserted after (optional)
// target_class: the class fo the error container (optional)

function createElementFromHTML(htmlString) {
  var div = document.createElement("div")
  div.innerHTML = htmlString.trim()

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild
}

export const insert_alert_container = function (
  alert_type,
  msg,
  dismiss_class,
  target_container,
  target_class
) {
  var alert_html =
    '<div id="flashbar_container" class="' +
    (target_class ? target_class : "container") +
    '">'
  alert_html += '<div class="pure-alert pure-alert-' + alert_type + '">'
  if (dismiss_class)
    alert_html +=
      '<a class="js-alert-close alert-close ' +
      dismiss_class +
      '" data-dismiss="alert">&times;</a>'
  else
    alert_html +=
      '<a class="js-alert-close alert-close" data-dismiss="alert">&times;</a>'
  alert_html += msg
  alert_html += "</div></div>"
  // Clear existing alert container first
  const flashbarContainer = document.querySelector("#flashbar_container")
  if (flashbarContainer) {
    flashbarContainer.remove()
  }
  document
    .querySelector(
      "#" + (target_container ? target_container : "content_container")
    )
    .parentNode.insertBefore(
      createElementFromHTML(alert_html),
      document.querySelector(
        "#" + (target_container ? target_container : "content_container")
      )
    )
}
