import Cookies from "js-cookie"

import { isTestMode } from "helpers/application"

export const FIRST_USER_CLICK_EVENT = "first-user-click"
export const BYPASS_FIRST_CLICK_COOKIE_NAME = "bypass_first_user_click"

// This function is run on every window load. TODO: Verify if this can cause loading issues
export const firstUserClickCheck = () => {
  if (Cookies.get(BYPASS_FIRST_CLICK_COOKIE_NAME)) {
    firstUserClickInit()
  } else {
    window.addEventListener("click", firstUserClickInit)
    window.addEventListener("scroll", firstUserClickInit)
    window.addEventListener("touchstart", firstUserClickInit)
  }
}

export const firstUserClickInit = () => {
  if (!isTestMode) {
    dataLayer.push({
      event: FIRST_USER_CLICK_EVENT,
    })
  }

  window.removeEventListener("click", firstUserClickInit)
  window.removeEventListener("scroll", firstUserClickInit)
  window.removeEventListener("touchstart", firstUserClickInit)
}
