import Cookies from "js-cookie"

import { submit_ga_event_click } from "helpers/analytics-helpers"
// import { isTestMode } from "helpers/application"

// This function is run on every window load as a precursor to loading FullStory
export const fsCheck = () => {
  if (Cookies.get("fs_uid") || Cookies.get("fs_load")) {
    fsInit()
  } else {
    window.addEventListener("click", fsInit)
  }
}

export const fsInit = () => {
  if (Cookies.get("fs_uid")) {
    // With fs_uid set, we don't need fs_load as a check anymore
    Cookies.remove("fs_load")
  } else {
    // This cookie is set in case FullStory isn't able to load for link clicks
    // before the next page
    Cookies.set("fs_load", "true")
  }

  // To save on sessions, we will only submit 25% of sessions to full story
  // We will randomize by using the last hex character of the cart token
  // let cartCharacter = Cookies.get(sl.config.cookies_config.cart_token).slice(-1)
  // if (Number("0x" + cartCharacter) % 4 == 0) {
  //   // This tells GTM to load the FullStory pixel
  //   if (!isTestMode) {
  //     dataLayer.push({
  //       event: "load-fullstory",
  //     })
  //   }
  // }
  // // This tells GTM to load the FullStory pixel
  // if (!isTestMode) {
  //   dataLayer.push({
  //     event: "load-fullstory",
  //   })
  // }

  window.removeEventListener("click", fsInit)
}

export const fsReady = () => {
  submit_ga_event_click(
    "FullStory",
    "loaded",
    FS.getCurrentSessionURL(),
    null,
    null,
    true
  )

  FS.setUserVars(
    Object.assign(
      {},
      {
        cartToken_str: Cookies.get(sl.config.cookies_config.cart_token),
        fullStoryUid_str: Cookies.get("fs_uid"),
      },
      sl.fullstory
    )
  )
}
