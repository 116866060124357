import lazySizes from "lazysizes"

lazySizes.cfg.customMedia = {
  "--desktop": "(min-width: 768px)",
  "--mobile": "(max-width: 767px)",
}
lazySizes.cfg.loadMode = 1
lazySizes.cfg.expFactor = 0
lazySizes.cfg.hFac = 0.4
lazySizes.cfg.ricTimeout = 500
lazySizes.cfg.throttleDelay = 200
lazySizes.cfg.init = false
lazySizes.cfg.loadHidden = false

// https://github.com/aFarkas/lazysizes/issues/545
import "lazysizes/plugins/attrchange/ls.attrchange.min.js"
import "lazysizes/plugins/custommedia/ls.custommedia.min.js"
import "lazysizes/plugins/bgset/ls.bgset.min.js"
import "lazysizes/plugins/parent-fit/ls.parent-fit.min.js"
import "dependencies/lazysizes/plugins/unveilhooks/ls.unveilhooks.custom"
