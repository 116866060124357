/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "core-js/stable"
import "regenerator-runtime/runtime"

import Cookies from "js-cookie"

import api from "apis"
import "dependencies/index"
import { insert_alert_container } from "helpers/alerts"
import {
  jewlr_link_decorator,
  setUTMCampaign,
  shorten_url,
  submit_ga_event_click,
  submit_ga_user_id,
  submitUserEvent,
} from "helpers/analytics-helpers"
import { sendUserData, setLocalStorage } from "helpers/application"
import { firstUserClickCheck } from "helpers/events/first-user-click"
import { fsCheck, fsReady } from "helpers/external/fullstory"
import { noExternalModals } from "helpers/modal"
import { storePref } from "helpers/user-prefs"
import loadCollectionUrls from "initializers/load-collection-urls"
import onAppLoad from "initializers/on-app-load"
import onIdleLoad from "initializers/on-idle-load"
import {
  afterLazySizesLoaded,
  sentryInitializer,
  initialLazySizes,
} from "initializers/utils"

// Deconstruct the window variables into constants for ESLint
const { domReady, idleLoad, jqueryReady, windowOnLoad } = window

// Trigger onAppLoad immediately
try {
  onAppLoad()
} catch (e) {
  console.error("onAppLoad Error", e)
}

const onLoadCallback = () => {
  try {
    if (typeof domReady !== "undefined") {
      domReady()
    }
  } catch (err) {
    console.error(err)
  }
  if (typeof windowOnLoad !== "undefined") {
    windowOnLoad()
  }
  fsCheck() // TODO Refactor Full Story to use generic first-user-click event
  firstUserClickCheck()
}

if (document.readyState !== "loading") {
  setTimeout(onLoadCallback, 0)
} else {
  window.addEventListener("load", onLoadCallback)
}

if (
  typeof window.requestIdleCallback !== "undefined" &&
  typeof idleLoad !== "undefined"
) {
  window.requestIdleCallback(idleLoad)
  window.requestIdleCallback(onIdleLoad)
} else {
  // Polyfill for Idle Load if requestIdleCallback doesn't exist
  window.addEventListener("load", () => {
    setTimeout(onIdleLoad, 1)
  })
}

// Master Window Exposures
// Set externally-exposed functions to the window
// These are meant to be "permanent", put temp stuff below
window.api = api
// window.appStoreV2 = store
window.noExternalModals = noExternalModals
window.jewlr_link_decorator = jewlr_link_decorator
window.loadCollectionUrls = loadCollectionUrls
window.sendUserData = sendUserData
window.storePref = storePref
window.submit_ga_event_click = submit_ga_event_click
window.submit_ga_user_id = submit_ga_user_id
window.submitUserEvent = submitUserEvent

window.generateDynamicCouponCode = async (template = "WCD") => {
  console.log("generateDynamicCouponCode() start")
  const url = "/secure/api/v10/coupons/gdcc"
  const body = { template }

  try {
    const response = await fetch(url, {
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }).then((data) => {
      return data.json()
    })

    const code = response.data.coupon_codes[0]
    const description = response.data.description
    console.log("generated code: ", code, "description: ", description)
    return { code, description }
  } catch (e) {
    return { code: "WELCOME10", description: "Save an Extra 10%" }
  }
}

// Set external library callbacks
window["_fs_ready"] = fsReady

// Set external library configs
window.assay_assured_config = {
  badgeOption: "tiny",
  clientid: "e5772b3e-0af6-11e6-84e4-001999b0ee30",
} //***do not change the client ID***

// TEMP: Fix insert_alert_container by using import helpers/alert in all relevant places
window.insert_alert_container = insert_alert_container

// TEMP: Fix shorten_url by removing ERB dependency in _product_tagging_box.html.erb
window.shorten_url = shorten_url

// Master Immediate Runs
setUTMCampaign()

// Master DOM Ready Event Handler
const masterDomReadyEvent = () => {
  // Transition those with user_obj cookies to
  // local storage.
  let cookieData = Cookies.get("user_obj")
  if (cookieData) {
    setLocalStorage(cookieData, "user_obj", 30, { isString: true })
    Cookies.remove("user_obj")
  }

  // Set shiptime cookie if not set and not in product page since it will be set in load-wizard
  // if (
  //   !is_na &&
  //   typeof appStore == "undefined" &&
  //   !Cookies.get(sl.config.shiptime_cookie_name)
  // ) {
  //   fetch(
  //     sl.config.secure_url +
  //       "/apis/estimated_delivery_days?country=" +
  //       sl.config.site_country_for_analytics,
  //     { method: "GET1" }
  //   )
  //     .then(function (response) {
  //       return response.json()
  //     })
  //     .then(function (data) {
  //       Cookies.set(sl.shiptime_cookie_name, data, { expires: 1, secure: true })
  //     })
  // }

  // Remove flash message on close
  document.addEventListener("click", function (event) {
    const target = event.target
    if (target.classList.contains("js-alert-close")) {
      const container = document.getElementById("flashbar_container")
      container.parentNode.removeChild(container)
    }
  })

  // Submit user id (if set)
  let userObj = window.localStorage.getItem("user_obj")
  if (userObj && userObj.length > 0) {
    userObj = JSON.parse(userObj)
    if (userObj.guid_token && userObj.guid_token !== "") {
      submit_ga_user_id(userObj.guid_token)
    }
  }

  try {
    if (typeof jqueryReady !== "undefined") {
      jqueryReady()
    }
  } catch (err) {
    console.log(err)
  }

  const rorScriptElement = document.getElementById("js-react-on-rails-context")
  // If page doesn't include a react component, initialLazySizes (edit2 tool)
  if (!rorScriptElement) {
    initialLazySizes()
  }
}

if (document.readyState !== "loading") {
  setTimeout(sentryInitializer, 0)
  setTimeout(masterDomReadyEvent, 0)
} else {
  document.addEventListener("DOMContentLoaded", sentryInitializer)
  document.addEventListener("DOMContentLoaded", masterDomReadyEvent)
}

// Master Lazy Loaded Event Listener
document.addEventListener("lazyloaded", afterLazySizesLoaded)

// Thinking of adding a window function here? STOP!
// Scroll up to Master Window Exposures comment and place it in an alphabetically
// correct location
// Also make sure the function is imported from a helper, do not put function
// contents in this pack file!
