import Cookies from "js-cookie"

export const noExternalModals = () => {
  // Add any other paths that shouldn't show JustUno, etc. modals here
  // Needs to return as a string-based "true" or "false" because the external
  // libraries like JustUno utilize string comparison, not truthy/falsey statements
  return location.hash.includes("#") ||
    Cookies.get("test_mode") == "true" ||
    !!window.localStorage.getItem("user_obj")
    ? "true"
    : "false"
}
