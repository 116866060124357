import api from "apis"

// This initializer populates the already-stored collection hash from the localStorage into the window
// object if the timestamp is within the expiration period. Otherwise, it loads the collection hash
// into the browser memory and localStorage.

const collectionUrlsExpiration = 1000 * 60 * 60 * 6 // 6 hours

const loadCollectionUrls = () => {
  const currentTime = new Date().getTime()
  let collectionUrlsJson = {}

  if (
    typeof window.localStorage !== "undefined" &&
    window.localStorage.getItem(sl.config.collection_urls_local_storage_name)
  ) {
    // If localStorage is available, then retrieve collectionUrls
    collectionUrlsJson = JSON.parse(
      window.localStorage.getItem(sl.config.collection_urls_local_storage_name)
    )
  }

  const { data, timestamp } = collectionUrlsJson

  if (
    data &&
    timestamp &&
    currentTime - timestamp <= collectionUrlsExpiration
  ) {
    // If collectionUrls is retrieved and timestamp has not expired, use it
    window.collectionUrls = data
  } else {
    // Otherwise, fetch collectionUrls
    api.get("/api/v5/collections").then((response) => {
      if (response && response.data && response.data.collection_urls) {
        window.collectionUrls = response.data.collection_urls
        // If localStorage is available, let's also populate into that for loadCollectionUrls
        if (typeof window.localStorage !== "undefined") {
          const collectionUrlsJson = {
            data: response.data.collection_urls,
            timestamp: currentTime,
          }
          window.localStorage.setItem(
            sl.config.collection_urls_local_storage_name,
            JSON.stringify(collectionUrlsJson)
          )
        }
      }
    })
  }
}

export default loadCollectionUrls
