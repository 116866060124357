import api from "apis"

export const to_currency = (number, opt) => {
  const number_with_delimiter = function (number, delimiter, separator) {
    var delimiter = delimiter || ","
    var separator = separator || "."

    var parts = number.toString().split(".")
    parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + delimiter)
    return parts.join(separator)
  }
  let unit =
    typeof defaultCurrency === "undefined"
      ? sl.config.default_currency
      : defaultCurrency // options["unit"] || "$"
  if (opt && "unit" in opt) {
    unit = opt.unit
  }

  try {
    let options = opt || {}
    // let unit = options["unit"] || sl.config.default_currency
    let precision = options["precision"] || 0
    let separator = precision > 0 ? options["separator"] || "." : ""
    let delimiter = options["delimiter"] || ","
    let taxRate = options.taxRate || 0
    let includeVat = options.includeVat || false
    if (includeVat) {
      number = number * (taxRate / 100.0 + 1.0)
    }

    let parts = parseFloat(number).toFixed(precision).split(".")
    if (parseInt(parts[0]) < 0) {
      unit = "-" + unit
      parts[0] = parts[0].replace("-", "")
    }
    if (precision > 0) {
      return (
        unit +
        number_with_delimiter(parts[0], delimiter) +
        separator +
        parts[1].toString()
      )
    } else {
      return unit + number_with_delimiter(parts[0], delimiter)
    }
  } catch (e) {
    return unit + number
  }
}
export const number_with_delimiter = (number, delimiter, separator) => {
  const parts = (number || "0.0").toString().split(".")
  parts[0] = parts[0].replace(
    /(\d)(?=(\d\d\d)+(?!\d))/g,
    "$1" + (delimiter || ",")
  )
  return parts.join(separator || ".")
}

export const getUsdPrice = () => {
  const cache = {}
  return async (price, currency) => {
    if (cache[`${price}${currency}`]) {
      return cache[`${price}${currency}`]
    }
    const result = await api.get(
      `${sl.config.secure_url}/api/v1/checkouts/usd_exchange_rate/${currency}/?price=${price}`
    )

    cache[`${price}${currency}`] = result
    return result
  }
}
